import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Type Brigade is a chance for Vancouver’s typography and type design community to get together. Now, it’s an opportunity for those same people to share.`}</p>
    <p>{`We’re excited to announce that Type Brigade’s `}<a parentName="p" {...{
        "href": "../call-for-speakers"
      }}>{`Call for Speakers`}</a>{` is `}<strong parentName="p">{`now open`}</strong>{`. We’re lucky to have two fantastic speakers lined up for the `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/186884552/"
      }}>{`next event`}</a>{`, and are actively seeking speakers for a subsequent meetups.`}</p>
    <p>{`Take a look at the `}<a parentName="p" {...{
        "href": "../call-for-speakers"
      }}>{`Call for Speakers`}</a>{` for all the information and feel to mention `}<a parentName="p" {...{
        "href": "http://twitter.com/typebrigade"
      }}>{`@typebrigade on Twitter`}</a>{` if you have any questions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      